import React from "react";
import * as pscss from "./posts.module.css";
import Post from "./post";
import SuggestedPost from "./suggestedPost";

const Posts = ({ posts }) => {

    return (
        posts.length > 0 ?
            <section className={pscss.psarea}>
                <div className={pscss.postsList}>
                    <h3 className={pscss.pheading}>All Posts</h3>
                    <div className={pscss.postsListContainer}>
                        {(posts || []).map((post, index) =>
                            <Post key={index} {...post} />
                        )}
                    </div>
                </div>
                {/* <div className={pscss.postsRight}>
                <h3>Popular Posts</h3>
                <div className={pscss.psrContainer}>
                    <SuggestedPost />
                    <SuggestedPost />
                    <SuggestedPost />
                    <SuggestedPost />
                    <SuggestedPost />
                </div>
            </div> */}
            </section>
            :
            <section className={pscss.psarea}>
                <h4 className={pscss.pnodata}>No Posts Found.</h4>
            </section>
    );
};

export default Posts;
