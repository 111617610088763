import React, { useState } from "react";
import Layout from "../components/layout";
import Posts from "../components/posts/posts";
// import PostDetail from "../components/posts/postDetail";
import { graphql } from "gatsby";

export default function Home({ data }) {
  const { allMdx: { nodes: posts } } = data;
  const [searchText, setSearchText] = useState('');

  const handleSearchTextChange = (data) => {
    setSearchText(data);
  }

  const filterPosts = (searchdata) => {
    return (posts || []).filter((post) => {
      const { title, slug, author, category } = post.frontmatter;
      if (
        title.toLowerCase().includes(searchdata.toLowerCase()) ||
        slug.toLowerCase().includes(searchdata.toLowerCase()) ||
        author.toLowerCase().includes(searchdata.toLowerCase()) ||
        category.toLowerCase().includes(searchdata.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  console.log(filterPosts(searchText), ' home');

  return (
    <>
      <Layout handleSearchTextChange={handleSearchTextChange}>
        <Posts posts={searchText.length > 0 ? filterPosts(searchText) : posts} />
      </Layout>
    </>
  )
}


export const query = graphql`
{
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    nodes {
      excerpt(truncate: true, pruneLength: 200)
      frontmatter {
        author
        category
        date(formatString: "MMM Do, YYYY")
        readTime
        slug
        title
      }
    }
  }
}
`