import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import * as pcss from "./post.module.css";
// import postImage from "../../images/christopher-gower.jpg";

const Post = ({ excerpt, frontmatter }) => {
    // console.log(excerpt, ' excerpt in post componetn');
    const { title, slug, date, author, category, readTime } = frontmatter;
    // const imagePath = '../../assets/code_image.jpg';
    return (
        <div className={pcss.parea}>
            {/* <div className={pcss.pimageContainer}>
                <StaticImage
                    src={imagePath} alt={'image'}
                    placeholder='blurred'
                    layout='fixed'
                    className={pcss.pimage}
                />
            </div> */}
            {/* <div className={pcss.pcontent}> */}
            <div className={pcss.pcontentContainer}>
                <div className={pcss.pdata}>
                    <Link to={`/posts/${slug}`}>
                        <h4 className={pcss.pheading}>
                            {title}
                        </h4>
                    </Link>
                    <p className={pcss.ppara}>
                        {excerpt}
                    </p>
                </div>
                <div className={pcss.pfooter}>
                    <div>{readTime} min read</div>
                    <div>{date}</div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default Post;
