import React from "react";
import { Link } from "gatsby";
import * as navCss from "./navbar.module.css";
import { FaDharmachakra } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";


const Navbar = ({ handleTextChange }) => {
    const handleSearch = (event) => {
        handleTextChange(event.target.value);
    }
    return (
        <div className={navCss.navbar}>
            <div className={navCss.navContainer}>
                <div className={navCss.navLogo}>
                    {/* <FaDharmachakra color={"#ffa5006e"} size={50} /> */}
                    <Link to='/'>Coding Tatva</Link>
                </div>
                <div className={navCss.navMenubar}>
                    {/* <div className={navCss.navSearchbar}> */}
                    <input className={navCss.navSearch} type="text"
                        onChange={handleSearch} />
                    {/* </div> */}
                </div>
                <div className={navCss.navGit}>
                    {/* <Link to='https://github.com/srikarb221'
                        target="_blank" rel="noopener noreferrer">

                    </Link> */}
                    <a href="https://github.com/srikarb221"
                        target="_blank" rel="noopener noreferrer">
                        <AiFillGithub size={38} />

                    </a>
                </div>
            </div>
        </div>
    );
};

export default Navbar;