import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <Wrapper>
      <div className="footer">
        <p>&copy;{new Date().getFullYear()}Coding Tatva. All rights reserved</p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
.footer {
    height: 7rem;
    text-align: center;
    background: var(--clr-black);
    display: grid;
    place-items: center;
  }
  .footer-icons {
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 0.5rem;
  }
  .footer-icons a {
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: var(--transition);
  }
  .footer-icons li {
    transition: var(--transition);
  }
  .footer-icons li:hover {
    transform: translateY(-10%);
  }
  .footer p {
    margin-bottom: 0;
    color: var(--clr-white);
    text-transform: camelcase;
    letter-spacing: var(--spacing);
  }
`

export default Footer;