// extracted by mini-css-extract-plugin
export var parea = "post-module--parea--152km";
export var pimageContainer = "post-module--pimageContainer--1SLQU";
export var pimage = "post-module--pimage--1ckyE";
export var pimagesize = "post-module--pimagesize--KkCEC";
export var pcontent = "post-module--pcontent--3GR8l";
export var pcontentContainer = "post-module--pcontentContainer--1lSKr";
export var pdata = "post-module--pdata--2D7kv";
export var pheading = "post-module--pheading---i_RG";
export var ppara = "post-module--ppara--3W1U4";
export var pfooter = "post-module--pfooter--3z1ws";